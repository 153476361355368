/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 0),
    cursor: 'pointer',
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleCol: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 600,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subTitleText: {
    fontSize: 11,
    color: theme.palette.primary.main,
  },
  shopName: {
    fontSize: 12,
    color: 'rgba(12, 18, 48, .8)',
  },
  statRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  statCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 1),
    borderRight: '1px solid #eee',
  },
  lastStatCol: {
    borderRight: '1px solid white',
  },
  labelText: {
    fontSize: 12,
    color: 'rgba(12, 18, 48, 0.5)',
    width: '100%',
    testAlign: 'left',
  },
  numberText: {
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    testAlign: 'left',
  },
}));

const MobileListItem = ({ item, itemOnClick }) => {
  const classes = useStyles();
  const { Product, Store } = item;
  return (
    <div
      aria-hidden="true"
      className={classes.root}
      onClick={() => itemOnClick(item)}
    >
      <div className={classes.titleRow}>
        <div className={classes.subTitleText}>{Product.dPD_NO}</div>
        <div className={classes.shopName}>{Store ? Store.dSTO_NAME : '-'}</div>
      </div>

      <div className={classes.titleRow}>
        <div className={classes.titleCol}>{Product.dPD_NAME}</div>
      </div>

      <div className={classes.statRow}>
        <div className={classes.statCol}>
          <div className={classes.labelText}>貨品類別</div>
          <div className={classes.numberText}>{Product.Category.dCA_NAME}</div>
        </div>
        <div className={classes.statCol}>
          <div className={classes.labelText}>3日銷量</div>
          <div className={classes.numberText}>{item.selltqtySum || '-'}</div>
        </div>
        <div className={classes.statCol}>
          <div className={classes.labelText}>即時倉存</div>
          <div className={classes.numberText}>{item.dSF_QTY || '-'}</div>
        </div>
        <div className={classes.statCol}>
          <div className={classes.labelText}>最低存倉量</div>
          <div className={classes.numberText}>
            {item.Minimum ? item.Minimum.minimumStorage : '-'}
          </div>
        </div>
        <div className={clsx(classes.statCol, classes.lastStatCol)}>
          <div className={classes.labelText}>最低落貨量</div>
          <div className={classes.numberText}>{Product.dpd_cdfc || '-'}</div>
        </div>
      </div>
      <div className={clsx(classes.statCol, classes.lastStatCol)}>
        <div className={classes.labelText}>貨品詳述</div>
        <div className={classes.numberText}>{Product.dPD_MEMO}</div>
      </div>
    </div>
  );
};

MobileListItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemOnClick: PropTypes.func.isRequired,
};

export default MobileListItem;
