/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */

/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Badge from '@material-ui/core/Badge';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import '../../../assets/css/custom.css';
import FullScreenDialog from '../../../components/Dialog/FullScreenDialog';
import {
  getStoreShortList,
  getCategoryShortList,
  getProductShortList,
  getMinimunSettingList,
  getManagerShopList,
  uploadMinCSV,
  getProductBrandList,
} from '../../../utils/api';
import StoreDropDownSelect from '../../../components/Forms/StoreDropDownSelect';
import CategoryDropDownSelect from '../../../components/Forms/CategoryDropDownSelect';
import UnitDropDownSelect from '../../../components/Forms/UnitDropDownSelect';
import MobileListItem from '../../../components/LowestStoragePage/MobileListItem';
import ShopAutoCompleteSelect from '../../../components/Forms/ShopAutoCompleteSelect';
import CategoryAutoComplete from '../../../components/Forms/CategoryAutoComplete';
import Pagination from '../../../components/Table/Pagination';
import { TABLE_PAGE_SIZE, STORE_OPTIONS, API_URL, PRODUCT_STATUS } from '../../../constant';
import { ProductSearchBox } from '../../../components/ShipmentPage';
import UnitAutoComplete from '../../../components/Forms/UnitAutoComplete';
import DialogContainer from '../../../components/Dialog/DialogContainer';
import StorageForm from '../../../components/LowestStoragePage/StorageForm';
import BrandDropDownSelect from '../../../components/Forms/BrandDropDownSelect';
import BrandAutoComplete from '../../../components/Forms/BrandAutoComplete';

const STOCK = [
  { value: 'all', label: '所有' },
  { value: 'hasStock', label: '有貨' },
  { value: 'outOfStock', label: '缺貨' },
];

const HAS_MIN_SETTING = [
  { value: 'all', label: '所有' },
  { value: 'true', label: '已設定最低存倉' },
  { value: 'false', label: '未設定最低存倉' },
];

const styles = theme => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    height: 'calc(100vh - 64px - 54px - 38px)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(2, 0, 0, 0),
      height: 'unset',
      borderRadius: 0,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    // padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  title: {
    fontSize: 25,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  mobileFilterRow: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1,
    },
  },
  filterGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  filterRow: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
  },
  filterRowItem: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px !important`,
  },
  filterRowItemWOPadding: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(2)}px !important`,
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  label: {
    color: '#0c1230',
    fontSize: 12,
  },
  resetButonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  tableRoot: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  listItemRoot: {
    width: '100%',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 54,
  },
  summaryRow: {
    marginTop: theme.spacing(2),
    whiteSpace: 'nowrap',
    minHeight: 60,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'unset',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
  },
  summaryCol: {
    display: 'inline-block',
    padding: theme.spacing(1),
    border: '1px solid rgba(51,51,51,0.4)',
    borderRadius: 5,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  summaryFirstCol: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  summaryLabel: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
  summaryText: {
    fontSize: 16,
    fontWeight: 700,
  },
  modalFilterCol: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modalBtnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
  dateBtn: {
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,
    textAlign: 'left',
    padding: theme.spacing(1, 0),
  },
  DatePickerContainer: {
    width: '100%',
  },
  dateBtnText: {
    textAlign: 'left',
    width: '100%',
  },
  filterContainer: {
    flex: 1,
  },
  chipRow: {
    width: '100%',
    padding: `0px ${theme.spacing(2)}px !important`,
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  btnGroupRoot: {
    minWidth: 180,
  },
  selectedItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
  },
  selectedItemSecondary: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: 'white !important',
  },
  filterRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    height: '100%',
  },
  filterCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filterLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  btnLabel: {
    textAlign: 'left',
    color: 'rgba(0,0,0,0.6)',
    fontSize: 16,
    width: '100%',
    fontWeight: 400,
    padding: '0px !important',
  },
  btnRoot: {
    height: 32,
    width: '100%',
    padding: '6px 8px 6px 0px !important',
  },
  filterBtnRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filterBtnText: {
    cursor: 'pointer',
    color: '#0c1230',

    '&:hover': {
      color: '#0c1230',
      opacity: 0.8,
    },
  },
  uploadContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0px 0px 25px 0px',
  },
  dropContainer: {
    padding: '40px 20px',
    border: '2px dotted rgba(51,51,51,0.2)',
    width: '100%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBtn: {
    marginLeft: 8,
  },
});

class LowestStorageListPage extends Component {
  constructor(Props) {
    super(Props);
    this.state = {
      minimumList: [],
      count: 0,
      page: 0,
      selectedMap: {},
      formVisible: false,
      selectedItem: null,
      batchUpdateVisible: false,
      stockStatus: 'all',
      hasMinSetting: 'all',
      productStatus: 'onSale',
      rowsPerPage: TABLE_PAGE_SIZE,
      searchValue: '',
      filterVisible: false,
      selectedShopList: [],
      selectedCatList: [],
      pageLoading: false,
      searchLoading: false,
      selectedType: 'shop',
      stores: [],
      sort: '',
      selectedProductList: [],
      selectProductVisible: false,
      selectedUnitList: [],
      selectedManager: [],
      managerShopList: [],
      uploadVisible: false,
      loading: false,
      selectedBrandList: [],
      brandList: [],
      subCatLoading: false,
    };
    this.pageOnChange = this.pageOnChange.bind(this);
    this.reset = this.reset.bind(this);
    this.modalDateRef = React.createRef();
    this.searchOnChange = this.searchOnChange.bind(this);
    this.shopListOnChange = this.shopListOnChange.bind(this);
    this.shopListOnDelete = this.shopListOnDelete.bind(this);
    this.catListOnChange = this.catListOnChange.bind(this);
    this.catListOnDelete = this.catListOnDelete.bind(this);
    this.shopToggleOnChange = this.shopToggleOnChange.bind(this);
    this.handleSort = _.debounce(this.handleSort, 150).bind(this);
    this.sortOptionOnChange = this.sortOptionOnChange.bind(this);
    this.selectProductOnClick = this.selectProductOnClick.bind(this);
    this.productOnSelect = this.productOnSelect.bind(this);
    this.productOnDelete = this.productOnDelete.bind(this);
    this.unitListOnChange = this.unitListOnChange.bind(this);
    this.unitListOnDelete = this.unitListOnDelete.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.managerOnChange = this.managerOnChange.bind(this);
    this.stockStatusOnChange = this.stockStatusOnChange.bind(this);
    this.minSettingOnChange = this.minSettingOnChange.bind(this);
    this.fileOnDrop = this.fileOnDrop.bind(this);
    this.brandListOnChange = this.brandListOnChange.bind(this);
    this.brandListOnDelete = this.brandListOnDelete.bind(this);
    this.statusOnChange = this.statusOnChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  async componentDidMount() {
    try {
      const managerRes = await getManagerShopList();
      this.setState({ managerShopList: managerRes.data });
      await this.getAllQuery();
      await this.getFilterValuesFromURL();
      await this.getMainStores();
      this.getMinimumList();
    } catch (err) {
      console.log(err);
    }
  }

  async getMinimumList() {
    this.setState({ pageLoading: true, searchLoading: true });
    const filter = this.getFilter();
    try {
      const minRes = await getMinimunSettingList(filter);
      this.setState({
        minimumList: minRes.data.rows,
        count: minRes.data.count,
        pageLoading: false,
        searchLoading: false,
      });
    } catch (err) {
      toast('網絡異常，請重試。');
      this.setState({ pageLoading: false, searchLoading: false });
    }
  }

  getAllQuery() {
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    if (currentUrlParams.toString() === "") {
      const qeury = localStorage.getItem('lowestStorage');
      const url = `${location.pathname}?${qeury}`;
      history.replace(url);
    }
  }

  async componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { search } = location;
    const preValues = queryString.parse(prevProps.location.search);
    const values = queryString.parse(search);
    if (!_.isEqual(preValues, values)) {
      await this.getAllQuery();
      await this.getFilterValuesFromURL();
      await this.getMainStores();
      this.getMinimumList();
    }
  }

  async getMainStores() {



    const filter = {
      limit: 500,
      offset: 0,
      type: 'S',
    }
    try {
      const storeRes = await getStoreShortList(filter);
      const stores = [];
      storeRes.data.rows.forEach(store => {
        if (store.dSTO_NO !== '01') {
          stores.push(store.dSTO_NO);
        }
      });
      this.setState({ stores });
    } catch (err) {
      // console.log(err);
    }
  }

  async getFilterValuesFromURL() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { location } = this.props;
    const { search } = location;
    const params = queryString.parse(search);
    const searchValue = params.q || '';
    const page = params.page ? parseInt(params.page, 10) - 1 : 0;
    const sort = params.sort || 'paidSumDESC';
    let selectedType = params.selectedType || 'shop';
    if (profile && profile.type === 'manager') {
      selectedType = 'all';
    }
    let status = 'all';
    const hasMinSetting = params.hasMinSetting || 'all'
    let productStatus = 'onSale';
    if (params.stockStatus) {
      status = params.stockStatus;
    }
    if (params.productStatus) {
      // eslint-disable-next-line prefer-destructuring
      productStatus = params.productStatus;
    }
    try {
      let selectedShopList = [];
      let selectedCatList = [];
      let selectedProductList = [];
      let selectedUnitList = [];
      let selectedManager = [];
      let selectedBrandList = [];
      let brandList = [];
      if (params.managers) {
        selectedManager = params.managers.split(',')
      }
      if (params.productids) {
        const filter = {
          productids: params.productids,
          limit: params.productids.split(",").length,
        };
        const productRes = await getProductShortList(filter);
        selectedProductList = [...productRes.data.rows]
      }
      if (params.catids) {
        const filter = {
          catids: params.catids,
          limit: 100,
        };
        const catRes = await getCategoryShortList(filter);
        const brandRes = await getProductBrandList({
          limit: 999,
          offset: 0,
          catids: params.catids,
        });
        selectedCatList = [...catRes.data.rows];
        brandList = [...brandRes.data.rows];
      }
      if (params.brands) {
        selectedBrandList = params.brands.split(',');
      }
      if (params.storeids) {
        const filter = {
          storeids: params.storeids,
          limit: 100,
        };
        const shopRes = await getStoreShortList(filter);
        selectedShopList = [...shopRes.data.rows]
      }
      if (params.unit) {
        const units = params.unit.split(',');
        selectedUnitList = units.map(unit => {
          return { value: unit, label: unit }
        });
      }

      this.setState({
        searchValue,
        selectedShopList,
        selectedCatList,
        selectedProductList,
        selectedType,
        page,
        sort,
        selectedUnitList,
        selectedManager,
        stockStatus: status,
        hasMinSetting,
        selectedBrandList,
        brandList,
        productStatus,
      });
    } catch (err) {
      this.setState({
        searchValue,
        page,
        stockStatus: status,
        hasMinSetting,
        productStatus,
      });
    }
  }

  getFilter() {
    const { location } = this.props;
    const { search } = location;
    const { rowsPerPage, selectedType, stores } = this.state;
    const params = queryString.parse(search);

    const profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : '';

    const filter = {
      limit: rowsPerPage,
      offset: (params.page - 1) * rowsPerPage || 0,
      q: params.q || null,
      catids: params.catids || null,
      brand: params.brands || null,
      sort: params.sort || null,
      stockStatus: params.stockStatus || null,
      productids: params.productids || null,
      unit: params.unit || null,
      showHasMinimumSetting: params.showHasMinimumSetting || null,
      productStatus: params.productStatus || null,
    };
    if (!params.productStatus) {
      filter.productStatus = 'onSale';
    } else if (params.productStatus === 'all') {
      filter.productStatus = null;
    } else {
      filter.productStatus = params.productStatus
    }
    if (params.storeids) {
      filter.storeids = params.storeids;
    } else if (selectedType === 'shop') {
      filter.storeids = stores.join();
    } else if (profile && profile.type === 'manager') {
      const index = _.findIndex(this.state.managerShopList, list => list.dsa_no === profile.staffid);
      if (index > -1) {
        filter.storeids = this.state.managerShopList[index].dsa_memo;
      } else {
        filter.storeids = null;
      }
    } else {
      filter.storeids = null;
    }
    return filter;
  }

  pageOnChange(e, page) {
    document.getElementById("productTable").scrollTop = 0;
    window.scrollTo(0, 0);
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    currentUrlParams.set('page', page + 1);
    localStorage.setItem('lowestStorage', currentUrlParams.toString());
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({ page }, () => {
      history.push(url);
    });
  }

  reset() {
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { location, history } = this.props;
    const url = `${location.pathname}`;
    localStorage.removeItem('lowestStorage');
    this.setState(
      {
        searchValue: '',
        stockStatus: 'all',
        hasMinSetting: 'all',
        productStatus: 'onSale',
        selectedShopList: [],
        selectedCatList: [],
        page: 0,
        selectedType: profile && profile.type === 'manager' ? 'all' : 'shop',
        sort: '',
        selectedProductList: [],
        selectedUnitList: [],
        brandList: [],
        selectedBrandList: [],
      },
      () => history.push(url),
    );
  }

  searchOnChange(e) {
    const text = e.target.value;
    if (text.length < 6) {
      this.setState({ searchValue: text });
    }
  }

  shopListOnChange(shops) {
    const { selectedShopList } = this.state;
    let temp = [...selectedShopList];
    if (shops && shops.length > 0) {
      shops.forEach(shop => {
        const { dSTO_NAME } = shop;
        const index = _.findIndex(temp, item => item.dSTO_NAME === dSTO_NAME);
        if (index === -1) {
          temp.push(shop);
        }
      });
    } else if (shops.length === 0) {
      temp = [];
    }
    this.setState({ selectedShopList: temp });
  }

  shopListOnDelete(shop) {
    const { selectedShopList } = this.state;
    const temp = [...selectedShopList];
    if (shop) {
      const { dSTO_NAME } = shop;
      const index = _.findIndex(temp, item => item.dSTO_NAME === dSTO_NAME);
      if (index === -1) {
        temp.push(shop);
      } else {
        temp.splice(index, 1);
      }
      this.setState({ selectedShopList: temp });
    }
  }

  catListOnDelete(cat) {
    const { selectedCatList } = this.state;
    const temp = [...selectedCatList];
    if (cat) {
      const { dCA_NAME } = cat;
      const index = _.findIndex(temp, item => item.dCA_NAME === dCA_NAME);
      if (index === -1) {
        temp.push(cat);
      } else {
        temp.splice(index, 1);
      }
      this.setState({ selectedCatList: temp });
    }
  }

  async catListOnChange(cats) {
    const { selectedCatList, brandList } = this.state;
    let temp = [...selectedCatList];
    if (cats && cats.length > 0) {
      cats.forEach(cat => {
        const { dCA_NAME } = cat;
        const index = _.findIndex(temp, item => item.dCA_NAME === dCA_NAME);
        if (index === -1) {
          temp.push(cat);
        }
      });
    } else if (cats.length === 0) {
      temp = [];
    }
    this.setState({ selectedCatList: temp, subCatLoading: true });
    if (cats && cats.length > 0) {
      try {
        const brandRes = await getProductBrandList({
          limit: 999,
          offset: 0,
          catids: cats.map(cat => cat.dCA_NO).join(','),
        });
        const tempBrandList = [...brandList, ...brandRes.data.rows];
        const newBrandList = _.uniqBy(tempBrandList, 'dpd_brand');
        this.setState({ subCatLoading: false, brandList: newBrandList });
      } catch (err) {
        console.log(err);
      } finally {
        this.setState({ subCatLoading: false });
      }
    } else {
      this.setState({ subCatLoading: false });
    }
  }

  unitListOnChange(units) {
    const { selectedUnitList } = this.state;
    let temp = [...selectedUnitList];
    if (units && units.length > 0) {
      units.forEach(unit => {
        const { value } = unit;
        const index = _.findIndex(temp, item => item.value === value);
        if (index === -1) {
          temp.push(unit);
        }
      });
    } else if (units.length === 0) {
      temp = [];
    }
    this.setState({ selectedUnitList: temp });
  }

  unitListOnDelete(unit) {
    const { selectedUnitList } = this.state;
    const temp = [...selectedUnitList];
    if (unit) {
      const { value } = unit;
      const index = _.findIndex(temp, item => item.value === value);
      if (index === -1) {
        temp.push(unit);
      } else {
        temp.splice(index, 1);
      }
      this.setState({ selectedUnitList: temp });
    }
  }

  renderFilterCount() {
    const {
      searchValue,
      selectedShopList,
      selectedCatList,
      selectedType,
      selectedUnitList,
      stockStatus,
      hasMinSetting,
      productStatus,
    } = this.state;
    let count = 0;
    if (searchValue) {
      count += 1;
    }
    if (selectedCatList.length > 0) {
      count += 1;
    }
    if (selectedUnitList.length > 0) {
      count += 1;
    }
    if (selectedType !== 'all') {
      count += 1;
    } else if (selectedShopList.length > 0) {
      count += 1;
    }
    if (stockStatus !== 'all') {
      count += 1;
    }
    if (hasMinSetting !== 'all') {
      count += 1;
    }
    if (productStatus !== 'all') {
      count += 1;
    }
    return count;
  }

  async managerOnChange(value) {
    this.setState({ selectedManager: value });
  }

  async shopToggleOnChange(option) {
    if (option) {
      this.setState({ selectedType: option });
    }
  }

  handleSort(sortType) {
    const { sort } = this.state;
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    let tempSort = '';
    if (sort.includes(sortType)) {
      if (sort.includes('ASC')) {
        tempSort = `${sortType}DESC`;
      }
    } else {
      tempSort = `${sortType}ASC`;
    }
    if (tempSort) {
      currentUrlParams.set('sort', tempSort);
    } else {
      currentUrlParams.delete('sort');
    }
    currentUrlParams.set('page', 1);
    localStorage.setItem('lowestStorage', currentUrlParams.toString());
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({ sort: tempSort, page: 0 }, () => history.push(url));
  }

  sortOptionOnChange(e) {
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    if (e.target.value) {
      currentUrlParams.set('sort', e.target.value);
    } else {
      currentUrlParams.delete('sort');
    }
    currentUrlParams.set('page', 1);
    localStorage.setItem('lowestStorage', currentUrlParams.toString());
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({ sort: e.target.value, page: 0 }, () => history.push(url));
  }

  selectProductOnClick() {
    this.setState({ selectProductVisible: true });
  }

  productOnSelect(product) {
    const { selectedProductList } = this.state;
    const temp = [...selectedProductList];
    const index = _.findIndex(temp, item => item.dPD_NO === product.dPD_NO);
    if (index === -1) {
      temp.push(product);
    } else {
      temp.splice(index, 1);
    }
    this.setState({ selectedProductList: temp });
  }

  productOnDelete(product) {
    const { selectedProductList } = this.state;
    const temp = [...selectedProductList];
    const index = _.findIndex(
      selectedProductList,
      item => item.dPD_NO === product.dPD_NO,
    );
    if (index !== -1) {
      temp.splice(index, 1);
    }
    this.setState({ selectedProductList: temp });
  }

  selectAll(productArray) {
    const { selectedProductList } = this.state;
    const temp = [...selectedProductList];
    productArray.forEach(product => {
      const index = _.findIndex(temp, item => item.dPD_NO === product.dPD_NO);
      if (index === -1) {
        temp.push(product);
      }
    });
    this.setState({ selectedProductList: temp });
  }

  onSelectAllClick() {
    const { selectedMap, minimumList } = this.state;
    if (Object.keys(selectedMap).length > 0) {
      this.setState({ selectedMap: {} });
    } else if (Object.keys(selectedMap).length === 0) {
      const temp = minimumList.reduce((map, item) => {
        return { ...map, [item.dSF_KEY]: item }
      }, {});
      this.setState({ selectedMap: temp });
    }
  }

  rowCheckBoxOnClick(item) {
    const { selectedMap } = this.state;
    const temp = { ...selectedMap }
    if (temp[item.dSF_KEY]) {
      delete temp[item.dSF_KEY];
    } else {
      temp[item.dSF_KEY] = item;
    }
    this.setState({ selectedMap: temp })
  }

  editOnPress(item) {
    this.setState({ selectedItem: item, formVisible: true })
  }

  stockStatusOnChange(e) {
    this.setState({ stockStatus: e.target.value });
  }

  statusOnChange(e) {
    this.setState({ productStatus: e.target.value });
  }

  minSettingOnChange(e) {
    // showHasMinimumSetting
    this.setState({ hasMinSetting: e.target.value });
  }

  async fileOnDrop(file) {
    this.setState({ loading: true });
    const temp = { ...file };
    if (
      temp[0].type &&
      (temp[0].type === 'text/csv' ||
      temp[0].type === 'application/vnd.ms-excel')
    ) {
      if (temp[0].size > 10000000) {
        toast('檔案大小應少於10Mb');
      } else {
        try {
          const formData = new FormData();
          formData.append('file', temp[0]);
          await uploadMinCSV(formData);
          toast('上載成功');
          this.getMinimumList();
          this.setState({ loading: false, uploadVisible: false });
        } catch (err) {
          if (
            err &&
            err.response.status === 400 &&
            err.response.data &&
            Array.isArray(err.response.data) &&
            err.response.data.length > 0
          ) {
            const { message, rowNumber } = err.response.data[0];
            toast(`${message} @Row number: ${rowNumber}`);
          } else {
            toast('上載失敗，請重試。');
          }
          this.setState({ loading: false, uploadVisible: false });
        }
      }
    } else {
      toast(`只接受 .csv 格式， 不接受 ${temp[0].type} 格式。`);
      this.setState({ loading: false, uploadVisible: false });
    }
  }

  brandListOnChange(brands) {
    const { selectedBrandList } = this.state;
    let temp = [...selectedBrandList];
    if (brands && brands.length > 0) {
      brands.forEach(brand => {
        const { dpd_brand } = brand;
        const index = _.findIndex(temp, item => item === dpd_brand);
        if (index === -1) {
          temp.push(dpd_brand);
        }
      });
    } else if (brands.length === 0) {
      temp = [];
    }
    this.setState({ selectedBrandList: temp });
  }

  brandListOnDelete(brand) {
    const { selectedBrandList } = this.state;
    const temp = [...selectedBrandList];
    if (brand) {
      const index = _.findIndex(temp, item => item === brand);
      if (index === -1) {
        temp.push(brand);
      } else {
        temp.splice(index, 1);
      }
      this.setState({ selectedBrandList: temp });
    }
  }

  onSearch() {
    const { location, history } = this.props;
    const { search } = location;
    const queries = new URLSearchParams(search);
    const {
      searchValue,
      selectedProductList,
      selectedShopList,
      selectedType,
      selectedCatList,
      selectedBrandList,
      selectedUnitList,
      productStatus,
      sort,
      stockStatus,
      hasMinSetting,
      selectedManager,
      managerShopList,
    } = this.state;
    queries.set('page', 1);

    // search filter
    if (searchValue) {
      queries.set('q', searchValue);
    } else {
      queries.delete('q');
    }

    // product filter
    if (selectedProductList && selectedProductList.length > 0) {
      const productids = selectedProductList.map(item => item.dPD_NO).join();
      queries.set('productids', productids);
    } else {
      queries.delete('productids');
    }

    // store filter
    if (selectedShopList && selectedShopList.length > 0) {
      const storeids = selectedShopList.map(store => store.dSTO_NO).join();
      queries.set('storeids', storeids);
    } else {
      queries.delete('storeids');
    }
    if (selectedType !== 'all' && selectedType !== 'manager') {
      const stores = localStorage.getItem('pf-store')
        ? JSON.parse(localStorage.getItem('pf-store'))
        : [];
      let shops = [];
      if (selectedType === 'shop') {
        shops = stores
          .filter(shop => shop.dSTO_NO !== '01')
          .map(item => item.dSTO_NO);
      } else {
        shops = ['01'];
      }
      queries.set('storeids', shops.join());
    } else if (selectedType === 'manager' && selectedManager.length > 0) {
      // manager
      let shops = [];
      selectedManager.forEach(manager => {
        const index = _.findIndex(managerShopList, list => list.dsa_name === manager);
        if (index > -1) {
          shops = [...shops, ...managerShopList[index].dsa_memo.split(',')];
        }
      });
      queries.set('storeids', shops.join());
      queries.set('managers', selectedManager.join());
    }
    queries.set('selectedType', selectedType);


    // category filter
    if (selectedCatList && selectedCatList.length > 0) {
      const catids = selectedCatList.map(catItem => catItem.dCA_NO).join();
      queries.set('catids', catids);
    } else {
      queries.delete('catids');
    }

    // brand filter
    if (selectedBrandList && selectedBrandList.length > 0) {
      const brands = selectedBrandList.map(brandItem => brandItem).join();
      queries.set('brands', brands);
    } else {
      queries.delete('brands');
    }

    // unit filter
    if (selectedUnitList && selectedUnitList.length > 0) {
      const newUnits = selectedUnitList.map(unitItem => unitItem.value).join();
      queries.set('unit', newUnits);
    } else {
      queries.delete('unit');
    }

    // stock status filter
    if (stockStatus) {
      queries.set('stockStatus', stockStatus);
    } else {
      queries.delete('stockStatus');
    }

    // status filter
    if (productStatus) {
      queries.set('productStatus', productStatus);
    } else {
      queries.delete('productStatus');
    }

    // has Minimum Setting filter
    if (hasMinSetting) {
      queries.set('hasMinSetting', hasMinSetting);
    } else {
      queries.delete('hasMinSetting');
    }

    // sort filter
    if (sort) {
      queries.set('sort', sort);
    } else {
      queries.delete('sort');
    }
    localStorage.setItem('lowestStorage', queries.toString());
    const url = `${location.pathname}?${queries.toString()}`;
    history.push(url);
  }

  render() {
    const { classes } = this.props;
    const {
      minimumList,
      count,
      selectedMap,
      selectedItem,
      formVisible,
      batchUpdateVisible,


      page,
      rowsPerPage,
      searchValue,
      filterVisible,
      selectedShopList,
      selectedCatList,
      pageLoading,
      searchLoading,
      selectedType,
      // sort,
      selectedProductList,
      selectProductVisible,
      selectedUnitList,
      selectedManager,
      managerShopList,

      stockStatus,
      hasMinSetting,
      productStatus,
      loading,
      uploadVisible,
      selectedBrandList,
      brandList,
      subCatLoading,
    } = this.state;

    // let sortDirection = 'false';
    // if (sort.includes("ASC")) {
    //   sortDirection = 'asc';
    // }
    // if (sort.includes("DESC")) {
    //   sortDirection = 'desc';
    // }
    const rowCount = minimumList.length;
    const numSelected = Object.keys(selectedMap).length;
    const profile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {};
    const { type } = profile;
    let isPermittedToEdit = false;
    if (type === 'admin' || type === 'manager' || type === 'staff') {
      isPermittedToEdit = true;
    }
    return (
      <div className={classes.root}>
        <Helmet>
          <title>最低存倉量</title>
        </Helmet>
        <div className={classes.loadingBar}>
          {pageLoading ? (
            <LinearProgress color="secondary" />
          ) : null}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>最低存倉量</div>
            <div className={classes.mobileFilterRow}>
              <Button
                onClick={() => this.setState({ filterVisible: true })}
                variant="outlined"
              >
                <Badge color="secondary" badgeContent={this.renderFilterCount()}>
                  篩選
                </Badge>
              </Button>
            </div>
          </div>
          <div className={classes.filterGrid}>
            <Grid container spacing={2} classes={{ root: classes.filterRow }}>
              <Grid item xs={12} sm={2} className={classes.filterCol}>
                <TextField
                  value={searchValue}
                  label="搜尋貨品"
                  placeholder="貨品編號"
                  classes={{ root: classes.input }}
                  onChange={this.searchOnChange}
                  disabled={searchLoading}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className={classes.filterRoot}>
                  <div className={classes.filterLabel}>貨品</div>
                  <Button
                    classes={{ root: classes.btnRoot, label: classes.btnLabel }}
                    onClick={this.selectProductOnClick}
                  >
                    {selectedProductList.length > 0
                      ? (
                        <Badge badgeContent={selectedProductList.length} color="secondary" style={{ padding: '0 5px' }}>
                          {`貨品`}
                        </Badge>
                      )
                      : <div className={classes.btnLabel}>貨品</div>}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6} sm={1}>
                <StoreDropDownSelect
                  selectedShopList={selectedShopList}
                  shopListOnChange={this.shopListOnChange}
                  shopListOnDelete={this.shopListOnDelete}
                  toggleOnChange={this.shopToggleOnChange}
                  managerOnChange={this.managerOnChange}
                  selectedType={type === 'manager' ? 'all' : selectedType}
                  selectedManager={selectedManager}
                  managerShopList={managerShopList}
                  isRestrictedSearch={type === 'frontline' || type === 'manager'}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <CategoryDropDownSelect
                  selectedCatList={selectedCatList}
                  catListOnChange={this.catListOnChange}
                  catListOnDelete={this.catListOnDelete}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <BrandDropDownSelect
                  selectedBrandList={selectedBrandList}
                  brandListOnChange={this.brandListOnChange}
                  brandListOnDelete={this.brandListOnDelete}
                  brandList={brandList}
                  loading={subCatLoading}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <UnitDropDownSelect
                  selectedUnitList={selectedUnitList}
                  unitListOnChange={this.unitListOnChange}
                  unitListOnDelete={this.unitListOnDelete}
                />
              </Grid>
              <Grid item xs={6} sm={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel>現存數量</InputLabel>
                  <Select
                    value={stockStatus}
                    onChange={this.stockStatusOnChange}
                  >
                    {STOCK.map(stock => (
                      <MenuItem value={stock.value} key={stock.label}>
                        {stock.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel>最低存倉</InputLabel>
                  <Select
                    value={hasMinSetting}
                    onChange={this.minSettingOnChange}
                  >
                    {HAS_MIN_SETTING.map(stock => (
                      <MenuItem value={stock.value} key={stock.label}>
                        {stock.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={1}>
                <FormControl className={classes.formControl}>
                  <InputLabel>貨品狀態</InputLabel>
                  <Select
                    value={productStatus}
                    onChange={this.statusOnChange}
                  >
                    {PRODUCT_STATUS.map(stock => (
                      <MenuItem value={stock.value} key={stock.label}>
                        {stock.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                classes={{ root: classes.resetButonContainer }}
              >
                <Button variant="outlined" color="primary" onClick={this.reset}>
                  重設
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onSearch}
                  classes={{ root: classes.searchBtn }}
                >
                  搜尋
                </Button>
                {isPermittedToEdit ? (
                  <a
                    href={`${API_URL}v1/minimum/importCsv`}
                    target="_blank"
                    style={{ marginLeft: 8, textDecoration: 'none' }}
                  >
                    <Button variant="outlined" color="secondary">
                      下載範本
                    </Button>
                  </a>
                ) : null}
                {isPermittedToEdit ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.setState({ uploadVisible: true })}
                    style={{ marginLeft: 8 }}
                  >
                    上載CSV
                  </Button>
                ) : null}

                {isPermittedToEdit ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.setState({ batchUpdateVisible: true })}
                    style={{ marginLeft: 8 }}
                    disabled={Object.keys(selectedMap).length === 0}
                  >
                    批量更改
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </div>
          <TableContainer classes={{ root: classes.tableRoot }} id="productTable">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={() => this.onSelectAllClick()}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  </TableCell>
                  <TableCell>貨品編號</TableCell>
                  <TableCell align="left">貨品名稱</TableCell>
                  <TableCell align="left">貨品類別</TableCell>
                  <TableCell align="left">貨品詳述</TableCell>
                  <TableCell align="left">店鋪/貨倉</TableCell>
                  <TableCell align="right">3日銷量</TableCell>
                  <TableCell align="right">即時倉存</TableCell>
                  <TableCell align="right">最低存倉量</TableCell>
                  <TableCell align="right">最低落貨量</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {minimumList.map((row, index) => {
                  const { Product, Store, dSF_KEY } = row;
                  const min = row.Minimum ? parseFloat(row.Minimum.minimumStorage) : null;
                  const shopQ = row.dSF_QTY ? parseFloat(row.dSF_QTY) : null;
                  let highlight = false;
                  if (min && shopQ && shopQ < min) {
                    highlight = true;
                  }
                  return (
                    <TableRow
                      key={dSF_KEY + index}
                      style={
                        highlight
                          ? { backgroundColor: 'rgba(255, 192, 203, 0.3)' }
                          : null
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedMap[row.dSF_KEY]  || false}
                          onChange={() => this.rowCheckBoxOnClick(row)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Product.dPD_NO}
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        {Product.dPD_NAME}
                      </TableCell>
                      <TableCell align="left">
                        {Product.Category.dCA_NAME}
                      </TableCell>
                      <TableCell align="left" style={{ width: 250 }}>
                        {Product.dPD_MEMO}
                      </TableCell>
                      <TableCell align="left">{Store ? Store.dSTO_NAME : '-'}</TableCell>
                      <TableCell align="right">{row.selltqtySum || '-'}</TableCell>
                      <TableCell align="right">{row.dSF_QTY || '-'}</TableCell>
                      <TableCell align="right">{row.Minimum ? row.Minimum.minimumStorage : '-'}</TableCell>
                      <TableCell align="right">
                        {Product.dpd_cdfc || '-'}
                      </TableCell>
                      {isPermittedToEdit ? (
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            size="small"
                            onClick={() => this.editOnPress(row)}
                          >
                            編輯
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.listRoot}>
            {minimumList.map((list, jndex) => {
              const min = list.Minimum ? parseFloat(list.Minimum.minimumStorage) : null;
              const shopQ = list.dSF_QTY ? parseFloat(list.dSF_QTY) : null;
              let highlight = false;
              if (min && shopQ && shopQ < min) {
                highlight = true;
              }
              return (
                <div
                  className={classes.listItemRoot} key={list.dSF_KEY + jndex} style={
                    highlight
                      ? { backgroundColor: 'rgba(255, 192, 203, 0.3)' }
                      : null
                  }>
                  <MobileListItem item={list} itemOnClick={(e) => this.editOnPress(e)} type={type} />
                </div>
              )
            })}
          </div>
          <div className={classes.paginationContainer}>
            <Pagination
              count={count}
              page={page}
              onChangePage={this.pageOnChange}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={e => {
                this.setState({ rowsPerPage: e.target.value }, () => this.getMinimumList());
              }}
            />
          </div>
        </Paper>
        <FullScreenDialog
          open={filterVisible}
          handleClose={() => this.setState({ filterVisible: false })}
          title="篩選"
        >
          <div className={classes.modalFilterCol}>
            <div className={classes.filterContainer}>
              <Grid container spacing={2} classes={{ root: classes.filterRow }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  classes={{ root: classes.filterRowItem }}
                >
                  <TextField
                    value={searchValue}
                    label="搜尋貨品"
                    placeholder="貨品編號"
                    classes={{ root: classes.input }}
                    onChange={this.searchOnChange}
                    disabled={searchLoading}
                  />
                </Grid>
                {type !== 'manager' ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    classes={{ root: classes.filterRowItemWOPadding }}
                  >
                    <ToggleButtonGroup
                      size="small"
                      classes={{ root: classes.btnGroupRoot }}
                      value={selectedType}
                      exclusive
                      onChange={(e, option) => this.shopToggleOnChange(option)}
                    >
                      {STORE_OPTIONS.map(option => (
                        <ToggleButton
                          value={option.value}
                          key={option.value}
                          classes={{ selected: classes.selectedItem }}
                        >
                          {option.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                ) : null}
                {type !== 'manager' ? (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    classes={{ root: classes.filterRowItemWOPadding }}
                  >
                    {selectedType === 'manager' ? (
                      <ToggleButtonGroup value={selectedManager} onChange={(e, value) => this.managerOnChange(value)} size="small" style={{ marginTop: 10 }}>
                        {managerShopList.map(manager => (
                          <ToggleButton
                            value={manager.dsa_name}
                            key={manager.dsa_name}
                            classes={{ selected: classes.selectedItemSecondary }}
                          >
                            {manager.dsa_name}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    ) : null}
                  </Grid>
                ) : null}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  classes={{ root: classes.filterRowItem }}
                >
                  <ShopAutoCompleteSelect
                    shopOnSelect={this.shopListOnChange}
                    disabled={selectedType !== 'all'}
                    isRestrictedSearch
                  />
                </Grid>
                <div className={classes.chipRow}>
                  {selectedShopList && selectedShopList.length > 0 && selectedType === 'all'
                    ? selectedShopList.map(item => (
                      <Chip
                        key={item.dSTO_NO}
                        label={item.dSTO_NAME}
                        onDelete={() => this.shopListOnDelete(item)}
                        classes={{ root: classes.chipRoot }}
                      />
                    ))
                    : null}
                  {selectedType === 'shop' ? (
                    <Chip
                      label="主倉店鋪"
                      classes={{ root: classes.chipRoot }}
                    />
                  ) : null}
                  {selectedType === 'warehouse' ? (
                    <Chip
                      label="理想倉"
                      classes={{ root: classes.chipRoot }}
                    />
                  ) : null}
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  classes={{ root: classes.filterRowItem }}
                >
                  <CategoryAutoComplete catOnSelect={this.catListOnChange} />
                </Grid>
                <div className={classes.chipRow}>
                  {selectedCatList && selectedCatList.length > 0
                    ? selectedCatList.map((item, jndec) => (
                      <Chip
                        key={item.dCA_NO + jndec}
                        label={item.dCA_NAME}
                        onDelete={() => this.catListOnDelete(item)}
                        classes={{ root: classes.chipRoot }}
                      />
                    ))
                    : null}
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  classes={{ root: classes.filterRowItem }}
                >
                  <BrandAutoComplete brandOnSelect={this.brandListOnChange} brandList={brandList} />
                </Grid>
                <div className={classes.chipRow}>
                  {selectedBrandList && selectedBrandList.length > 0
                    ? selectedBrandList.map((item, jndec) => (
                      <Chip
                        key={item + jndec}
                        label={item}
                        onDelete={() => this.brandListOnDelete(item)}
                        classes={{ root: classes.chipRoot }}
                      />
                    ))
                    : null}
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  classes={{ root: classes.filterRowItem }}
                >
                  <UnitAutoComplete unitOnSelect={this.unitListOnChange} />
                </Grid>
                <div className={classes.chipRow}>
                  {selectedUnitList && selectedUnitList.length > 0
                    ? selectedUnitList.map(item => (
                      <Chip
                        key={item.value}
                        label={item.label}
                        onDelete={() => this.unitListOnDelete(item)}
                        classes={{ root: classes.chipRoot }}
                      />
                    ))
                    : null}
                </div>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  classes={{ root: classes.filterRowItem }}
                >
                  <div className={classes.filterRoot}>
                    <div className={classes.filterLabel}>貨品</div>
                    <Button
                      classes={{ root: classes.btnRoot, label: classes.btnLabel }}
                      onClick={this.selectProductOnClick}
                    >
                      {selectedProductList.length > 0
                        ? (
                          <Badge badgeContent={selectedProductList.length} color="secondary" style={{ padding: '0 5px' }}>
                            {`貨品`}
                          </Badge>
                        )
                        : <div className={classes.btnLabel}>貨品</div>}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} classes={{ root: classes.filterRowItem }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>現存數量</InputLabel>
                    <Select
                      value={stockStatus}
                      onChange={this.stockStatusOnChange}
                    >
                      {STOCK.map(stock => (
                        <MenuItem value={stock.value} key={stock.label}>
                          {stock.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} classes={{ root: classes.filterRowItem }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>最低存倉</InputLabel>
                    <Select
                      value={hasMinSetting}
                      onChange={this.minSettingOnChange}
                    >
                      {HAS_MIN_SETTING.map(stock => (
                        <MenuItem value={stock.value} key={stock.label}>
                          {stock.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} classes={{ root: classes.filterRowItem }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>貨品狀態</InputLabel>
                    <Select
                      value={productStatus}
                      onChange={this.statusOnChange}
                    >
                      {PRODUCT_STATUS.map(stock => (
                        <MenuItem value={stock.value} key={stock.label}>
                          {stock.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalBtnContainer}>
              <div style={{ flex: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.reset}
                  style={{ width: '100%' }}
                >
                  重設
                </Button>
              </div>
              <div style={{ width: 10 }} />
              <div style={{ flex: 3 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.setState({ filterVisible: false });
                    this.onSearch();
                  }}
                  style={{ width: '100%' }}
                >
                  搜尋
                </Button>
              </div>
            </div>
          </div>
        </FullScreenDialog>
        <ProductSearchBox
          open={selectProductVisible}
          handleClose={() => this.setState({ selectProductVisible: false })}
          productOnSelect={this.productOnSelect}
          productOnDelete={this.productOnDelete}
          selectedProductList={selectedProductList}
          clearProduct={() => this.setState({ selectedProductList: [], selectProductVisible: false })}
          selectAll={this.selectAll}
        />
        <DialogContainer
          title="編輯最低存倉量"
          open={formVisible}
          closeOnPress={() => this.setState({ formVisible: false })}
        >
          <div className={classes.formContainer}>
            <StorageForm
              formSubmit={() => {
                this.setState({ formVisible: false });
                this.getMinimumList();
              }}
              initValues={selectedItem}
            />
          </div>
        </DialogContainer>

        <DialogContainer
          title="批量編輯最低存倉量"
          open={batchUpdateVisible}
          closeOnPress={() => this.setState({ batchUpdateVisible: false })}
        >
          <div className={classes.formContainer}>
            <StorageForm
              formSubmit={() => {
                this.setState({ batchUpdateVisible: false, selectedMap: {} });
                this.getMinimumList();
              }}
              batchUpdateItems={selectedMap}
            />
          </div>
        </DialogContainer>
        {uploadVisible ? (
          <DialogContainer
            open={uploadVisible}
            handleClose={() => this.setState({ uploadVisible: false })}
            title="上載CSV"
            confirmLoading={loading}
          >
            <div className={classes.uploadContainer}>
              <Dropzone ref={this.dropzoneRef} onDrop={this.fileOnDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className={classes.dropContainer}>
                    <input {...getInputProps()} />
                    <CloudUploadIcon
                      style={{ color: 'rgba(51,51,51,0.6)' }}
                      fontSize="large"
                    />
                    <div className={classes.dropText}>拉入CSV 或 點擊此處</div>
                  </div>
                )}
              </Dropzone>
            </div>
          </DialogContainer>
        ) : null}
      </div>
    );
  }
}

LowestStorageListPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(LowestStorageListPage));
